import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {companyAPI, searchAPI} from "../../../api/api";
import {toast} from "react-toastify";
import {
    Card,
    CardContent,
    Chip,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Checkbox,
    FormControlLabel
} from "@mui/material";
import axios from "axios";

const SearchEquipment = () => {
    const [openSearchWindow, setOpenSearchWindow] = React.useState(false);
    const [searchRequest, setSearchRequest] = React.useState('');
    const [searchResults, setSearchResults] = React.useState([]);
    const [nonStrictSearch, setNonStrictSearch] = React.useState(true);
    const [selectedCategories, setSelectedCategories] = React.useState([]);
    const [industriesList, setIndustriesList] = React.useState([]);
    const [selectedResults, setSelectedResults] = React.useState([]);
    const [selectAll, setSelectAll] = React.useState(false);

    const handleClickOpenSearchWindow = () => {
        setOpenSearchWindow(true);
        getIndustriesList();
    };

    const handleClose = () => {
        setOpenSearchWindow(false);
        setSearchRequest('');
        setSearchResults([]);
        setSelectedCategories([]);
        setNonStrictSearch(true);
        setSelectedResults([]);
        setSelectAll(false);
    };

    const handleSearchChange = (value) => {
        setSearchRequest(value);
        if (value.length > 2 || selectedCategories.length > 0) {
            getSearchResults({search: value, categories: selectedCategories?.map(category => category.id)});
        } else if (value?.length === 0 && selectedCategories?.length === 0) {
            setSearchResults([]);
        }
    };

    const getSearchResults = async (search) => {
        try {
            const response = await searchAPI.getUseCases(search);
            if (response.status === 1) {
                setSearchResults(response.data);
            } else {
                console.error(response.message);
                toast.error('Ошибка: ' + response.message);
            }
        } catch (error) {
            console.error(error.message);
            toast.error('Ошибка: ' + error.message);
        }
    };

    const getIndustriesList = async () => {
        try {
            const response = await companyAPI.getIndustriesList();
            if (response.status === 1) {
                setIndustriesList(response.data);
            } else {
                console.error('Error fetching industries list:', response.message);
            }
        } catch (error) {
            console.error('Error fetching industries list:', error.message);
        }
    };

    const handleCategoryChange = (event) => {
        const selected = event.target.value;
        setSelectedCategories(selected);
        if (searchRequest.length > 2 || selected.length > 0) {
            getSearchResults({search: searchRequest, categories: selected?.map(category => category.id)});
        } else if (selected.length === 0 && searchRequest.length === 0) {
            setSearchResults([]);
        }
    };

    const handleSwitchChange = (event) => {
        setNonStrictSearch(event.target.checked);
    };

    const handleSelectAll = (event) => {
        setSelectAll(event.target.checked);
        if (event.target.checked) {
            setSelectedResults(searchResults?.map(item => item.id));
        } else {
            setSelectedResults([]);
        }
    };

    const handleSelectResult = (id) => {
        setSelectedResults((prevSelected) =>
            prevSelected.includes(id) ? prevSelected.filter((itemId) => itemId !== id) : [...prevSelected, id]
        );
    };

    const buildJSONRequest = () => {
        const currentDateTime = new Date().toISOString().replace(/T/, '_').replace(/:/g, '_').replace(/\..+/, '');
        const filename = `підбірка_${currentDateTime}`;

        return {
            template_id: 2,
            filename: filename,  // Додаємо filename до запиту
            data: searchResults
                .filter((item) => selectedResults.includes(item.id))
                .map((item, index) => ({
                    // Об'єднуємо список обладнання у рядки з переносом
                    name: {
                        type: "text",
                        value: item.equipment_list ? item.equipment_list.join("\n") : item.product_name || ''
                    },
                    text: {type: "text", value: item.description || ''},
                    // image: { type: "image", value: "https://example.com/image.png" }  // Placeholder for image URL
                }))
        };
    };

    const handleSendRequest = async () => {
        const jsonRequest = buildJSONRequest();

        try {
            const response = await axios.post("https://passports.akon.com.ua/api/build-document", jsonRequest, {
                headers: {
                    "Content-Type": "application/json"
                },
            });

            // Перевіряємо відповідь на наявність URL для завантаження
            if (response?.data && response?.data?.url) {
                const downloadUrl = response?.data?.url;

                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `${jsonRequest.filename}.docx`);
                document.body.appendChild(link);
                link.click();
                link.remove();

                toast.success("Документ успешно отправлен");
            } else {
                toast.error('Ошибка при отправке документа: ' + response?.data?.message)

            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Ошибка при отправке документа: " + error.message);
        }
    };


    return (
        <>
            <NavLink to="#" onClick={handleClickOpenSearchWindow}>
                <div><FontAwesomeIcon icon={faSearch}/> Референс</div>
            </NavLink>
            <Dialog fullWidth={true} maxWidth={"xl"} open={openSearchWindow} onClose={handleClose}>
                <DialogTitle>
                    Поиск по референсу оборудования
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel id="categories-label">Отрасли</InputLabel>
                                <Select
                                    labelId="categories-label"
                                    multiple
                                    value={selectedCategories}
                                    onChange={handleCategoryChange}
                                    renderValue={(selected) => selected?.map(category => category.name).join(', ')}
                                    disabled={industriesList.length === 0}
                                >
                                    {industriesList?.length > 0 &&
                                        (industriesList || []).map((industry) => (
                                            <MenuItem key={industry.id} value={industry}>
                                                {industry.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        value={searchRequest}
                        onChange={(e) => handleSearchChange(e.currentTarget.value)}
                        autoFocus
                        margin="dense"
                        label="Что ищем?"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    {searchResults?.length > 0 && (
                        <FormControlLabel
                            control={<Checkbox checked={selectAll} onChange={handleSelectAll}/>}
                            label="Выбрать все"
                        />
                    )}
                    <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                        {searchResults?.length > 0 &&
                            searchResults?.map((item) => (
                                <Card key={item.id} sx={{marginBottom: "16px", padding: "16px"}}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={1}>
                                            <Checkbox
                                                checked={selectedResults.includes(item.id)}
                                                onChange={() => handleSelectResult(item.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <CardContent>
                                                <Typography variant="h6">
                                                    {nonStrictSearch ? (
                                                        item.equipment_list?.map((equipment, index) => (
                                                            <span key={index}>{index + 1}. {equipment}<br/></span>
                                                        ))
                                                    ) : (
                                                        item.product_name
                                                    )}
                                                </Typography>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <CardContent>
                                                <Typography
                                                    variant="body2">{item.description ?? "Описание отсутствует"}</Typography>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                    <Divider/>
                                </Card>
                            ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Закрыть</Button>
                    <Button variant="contained" color="primary" onClick={handleSendRequest}
                            disabled={selectedResults.length === 0}>
                        Экспорт в docx
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SearchEquipment;
