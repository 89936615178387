import React from 'react';
import s from '../SalesPersonal.module.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Cookies from "js-cookie";

const Statistics = (props) => {
    const getCount = (type) => {
        let count = 0;
        props.allActivity.forEach(k => {
            if (type === k.type) count += 1;
        });
        return count;
    };

    const getCountOpl = (type) => {
        let count = 0;
        props.allActivity.forEach(k => {
            if (type === k.type && k.paid != null) count += 1;
        });
        return count;
    };

    const getCountNotOpl = (type) => {
        let count = 0;
        props.allActivity.forEach(k => {
            if (type === k.type && k.paid == null) count += 1;
        });
        return count;
    };

    const getCountNotFilledUseCases = () => {
        let count = 0;
        props.allActivity.forEach(k => {
            if (k.type === 666&& k.filled_usecase == 0) count += 1;
        });
        return count;
    }
    const getCountFilledUseCases = () => {
        let count = 0;
        props.allActivity.forEach(k => {
            if (k.type === 666 && k.filled_usecase == 1) count += 1;
        });
        return count;
    }


    let managerID = Cookies.get('id');
    return (
        <div>
            <div className={s.statistics}>
                <div className={props.showUseCases ? s.purple + " " + s.active : s.purple}>
                    <span onClick={() => props.show(666)}>Референс</span>
                </div>
                <div className={props.showCheck ? s.green + " " + s.active : s.green}>
                    <span onClick={() => props.show(1)}>Счёта</span>
                    <NavLink to="/admin/addNewCheck/index.php" target="_blank">
                        <FontAwesomeIcon icon={faPlus} />
                    </NavLink>
                </div>
                <div className={props.showKp ? s.blue + " " + s.active : s.blue}>
                    <span onClick={() => props.show(2)}>КП</span>
                    <NavLink to={'/kp/add/' + managerID} target="_blank">
                        <FontAwesomeIcon icon={faPlus} />
                    </NavLink>
                </div>
                <div className={props.showTestCheck ? s.blueTest + " " + s.active : s.blueTest}>
                    <span onClick={() => props.show(6)}>Тест</span>
                    <NavLink to="/check-t" target="_blank">
                        <FontAwesomeIcon icon={faPlus} />
                    </NavLink>
                </div>
                <div className={props.showMeeting ? s.greenDopText + " " + s.active : s.greenDopText}>
                    <span onClick={() => props.show(5)}>Встречи</span>
                    <NavLink to={"/sales/meeting/" + managerID} target="_blank">
                        <FontAwesomeIcon icon={faPlus} />
                    </NavLink>
                </div>
                <div className={props.showMessage ? s.orange + " " + s.active : s.orange} onClick={() => props.show(3)}>Сообщения</div>
                <div className={props.showTech ? s.red + " " + s.active : s.red}>
                    <span onClick={() => props.show(4)}>Тех.</span>
                    <NavLink to="/techconsultation" target="_blank">
                        <FontAwesomeIcon icon={faPlus} />
                    </NavLink>
                </div>
            </div>
            <div className={s.statistics}>
                <div onClick={() => props.show(666)}>
                    <div
                        className={props.showUseCases ? s.circle + " " + s.purpleBg + " " + s.active : s.circle + " " + s.purpleBg}>
                        {getCountNotFilledUseCases() + getCountFilledUseCases()}
                    </div>
                    <div
                        className={props.showUseCases ? s.circle_min + " " + s.greenDop + " " + s.active : s.circle_min + " " + s.greenDop}>
                        {getCountFilledUseCases()}
                    </div>
                    <div
                        className={props.showUseCases ? s.circle_min + " " + s.redDop + " " + s.active : s.circle_min + " " + s.redDop}>
                        {getCountNotFilledUseCases()}
                    </div>
                </div>
                <div onClick={() => props.show(1)}>
                    <div
                        className={props.showCheck ? s.circle + " " + s.greenBg + " " + s.active : s.circle + " " + s.greenBg}>
                        {getCount(1)}
                    </div>
                    <div
                        className={props.showCheck ? s.circle_min + " " + s.greenDop + " " + s.active : s.circle_min + " " + s.greenDop}>
                    {getCountOpl(1)}
                    </div>
                    <div className={props.showCheck ? s.circle_min + " " + s.redDop + " " + s.active : s.circle_min + " " + s.redDop}>
                        {getCountNotOpl(1)}
                    </div>
                </div>
                <div onClick={() => props.show(2)}>
                    <div className={props.showKp ? s.circle + " " + s.blueBg + " " + s.active : s.circle + " " + s.blueBg}>
                        {getCount(2)}
                    </div>
                    <div className={props.showKp ? s.circle_min + " " + s.greenDop + " " + s.active : s.circle_min + " " + s.greenDop}>
                        {getCountOpl(2)}
                    </div>
                    <div className={props.showKp ? s.circle_min + " " + s.redDop + " " + s.active : s.circle_min + " " + s.redDop}>
                        {getCountNotOpl(2)}
                    </div>
                </div>
                <div onClick={() => props.show(6)}>
                    <div className={props.showTestCheck ? s.circle + " " + s.blueBgTest + " " + s.active : s.circle + " " + s.blueBgTest}>
                        {getCount(6)}
                    </div>
                    <div className={props.showTestCheck ? s.circle_min + " " + s.redDop + " " + s.active : s.circle_min + " " + s.redDop}>
                        {getCountNotOpl(6)}
                    </div>
                </div>
                <div>
                    <div className={props.showMeeting ? s.circle + " " + s.greenDopBg + " " + s.active : s.circle + " " + s.greenDopBg} onClick={() => props.show(5)}>
                        {getCount(5)}
                    </div>
                </div>
                <div>
                    <div className={props.showMessage ? s.circle + " " + s.orangeBg + " " + s.active : s.circle + " " + s.orangeBg} onClick={() => props.show(3)}>
                        {getCount(3)}
                    </div>
                </div>
                <div>
                    <div className={props.showTech ? s.circle + " " + s.redBg + " " + s.active : s.circle + " " + s.redBg} onClick={() => props.show(4)}>
                        {getCount(4)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Statistics;
