import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Switch,
    FormControlLabel, Grid
} from '@mui/material';

const Filters = ({filters, onChange}) => {
    const handleFilterChange = (event, filterName) => {
        const value = event.target.value;
        onChange({[filterName]: value});
    };
    const handleGroupContactsChange = (event) => {
        onChange({groupContacts: event.target.checked});
    };

    const renderSelect = (label, filterName, options) => (
        <FormControl variant="outlined" margin="normal" style={{width:'100%'}}>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                value={filters[filterName]}
                onChange={(event) => handleFilterChange(event, filterName)}
                input={<OutlinedInput label={label}/>}
                renderValue={(selected) => selected.join(', ')}
            >
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={filters[filterName].indexOf(option) > -1}/>
                        <ListItemText primary={option}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    return (
        <div>
            <Grid container>
                <Grid item xs={2}>
                    {renderSelect('Year', 'year', [2023, 2024, 2025])}
                </Grid>
                <Grid item xs={2}>
                    {renderSelect('Quarter', 'quarter', ['Q1', 'Q2', 'Q3', 'Q4'])}
                </Grid>
                <Grid item xs={2}>
                    {renderSelect('Month', 'month', Array.from({length: 12}, (_, i) => i + 1))}
                </Grid>
                <Grid item xs={4}>
                    {renderSelect('Managers', 'manager', ['44', '486', '1055', '1056', '1061'])}
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={filters.groupContacts}
                                onChange={handleGroupContactsChange}
                                name="groupContacts"
                                color="primary"
                            />
                        }
                        label="Group Contacts"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default Filters;
