import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom'; // Replace withRouter with useParams
import s from './Meeting.module.css';
import main from '../../Main.module.css';
import Cookies from 'js-cookie';
import AnyFormatDate from '../../Production/common/AnyFormatDate/AnyFormatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AddMeeting from './AddMeeting/AddMeeting';
import Modal from '../../Production/common/Modal/Modal';
import ChangeMeetingText from './Modal/ChangeMeetingText';
import DeleteMeeting from './Modal/DeleteMeeting';
import CloseMeeting from './Modal/CloseMeeting';
import ChangeDateReminder from '../Kp/Modal/ChangeDateReminder';
import MenuManager from '../common/MenuManager/MenuManager';

const Meeting = (props) => {
    let dateStart, dateEnd;
    let idManager = Cookies.get('id');

    const { manager } = useParams(); // Access route parameters using useParams

    // Sorting by planned date
    let asd = (arr) => {
        return arr?.sort((a, b) => (a.date_plan > b.date_plan ? 1 : -1));
    };

    const getAgent = (a) => {
        let result = '';
        let array = a.split(',');
        for (let i = 0; i < array.length; i++) {
            let name = props.allAgents.find((k) => k.id == array[i].trim());
            if (result.length === 0) {
                if (name) result = name.name;
            } else {
                if (name) result = result + ', ' + name.name;
            }
        }
        return result;
    };

    let [meeting, setMeeting] = useState(false);
    let [change, setChange] = useState(false);
    let [deleteMeeting, setDelete] = useState(false);
    let [closeMeeting, setCloseMeeting] = useState(false);
    let [changeDate, setChangeDate] = useState(false);

    return (
        <div>
            {meeting && (
                <Modal active={meeting} setActive={setMeeting}>
                    <AddMeeting {...props} setActive={setMeeting} idManager={idManager} />
                </Modal>
            )}
            {change && (
                <Modal active={change} setActive={setChange}>
                    <ChangeMeetingText
                        {...props}
                        setActive={setChange}
                        active={change}
                        changeMeetingText={props.changeMeetingText}
                        idManager={idManager}
                    />
                </Modal>
            )}
            {deleteMeeting && (
                <Modal active={deleteMeeting} setActive={setDelete}>
                    <DeleteMeeting
                        {...props}
                        setActive={setDelete}
                        active={deleteMeeting}
                        deleteMeeting={props.deleteMeeting}
                        idManager={idManager}
                    />
                </Modal>
            )}
            {closeMeeting && (
                <Modal active={closeMeeting} setActive={setCloseMeeting}>
                    <CloseMeeting
                        {...props}
                        setActive={setCloseMeeting}
                        active={closeMeeting}
                        сloseMeeting={props.closeMeeting}
                        idManager={idManager}
                    />
                </Modal>
            )}
            {changeDate && (
                <Modal active={changeDate} setActive={setChangeDate}>
                    <ChangeDateReminder
                        {...props}
                        setActive={setChangeDate}
                        active={changeDate}
                        personalId={idManager}
                        changeDateReminderKp={props.changeDateReminderKp}
                        type="3"
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                    />
                </Modal>
            )}
            {(Cookies.get('id') == 1 || Cookies.get('id') == 44 || Cookies.get('id') == 34) && (
                <MenuManager link={'/sales/meeting'} idNow={manager} />
            )}

            <div className={main.twoBlocksGrid}>
                <div>
                    <h1>Запланированные встречи</h1>
                </div>
                <div className={main.right}>
                    <input
                        type="button"
                        value="Добавить"
                        className={main.inputAdd}
                        onClick={() => setMeeting(true)}
                    />
                </div>
            </div>
            <div className={s.dostup}>
                <NavLink to={'/sales/allCompany/' + Cookies.get('id')}>
                    <span>Список всех компаний</span>
                </NavLink>
                <NavLink to={'/kp/' + Cookies.get('id')}>
                    <span>Планировщик</span>
                </NavLink>
            </div>
            <div className={s.mainKp + ' ' + main.header}>
                <div>#</div>
                <div>Компания</div>
                <div>Сотрудник(и)</div>
                <div>Плановая дата</div>
                <div>Задача на встречу</div>
                <div>Действия</div>
            </div>
            {asd(props?.allMeetingWithManager)?.map((k) => {
                return (
                    <div key={'kp' + k.id} className={s.mainKp + ' ' + main.borderB}>
                        <div>{k.id}</div>
                        <div>
                            <NavLink to={'/company/profile/' + k.company_id} target="_blank">
                                {k.name_with_akon}
                            </NavLink>
                        </div>
                        <div>{getAgent(k.agents_ids)}</div>
                        <div>
                            <AnyFormatDate date={k.date_plan} />
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                onClick={() => setChangeDate(k.id)}
                                className={main.icon}
                            />
                        </div>
                        <div>
                            {k.meeting_task_text}{' '}
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                onClick={() => setChange(k.id)}
                                className={main.icon}
                            />
                        </div>
                        <div className={main.twoBlocksGrid}>
                            <div>
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    onClick={() => setCloseMeeting(k.id)}
                                    className={main.green}
                                />
                            </div>
                            <div>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={() => setDelete(k.id)}
                                    className={main.red}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Meeting;
