import React, { useState } from 'react';
import MenuStatistics from '../MenuStatistics';
import s from './StatisticsModelModules.module.css';

const StatisticsModelModules = (props) =>{
    // console.log(props.moduleSalesStatistics)
    let [year, setYear] = useState("2023");
    let handleClick = (e)=>{
        props.getModuleSalesStatistics(e);
        setYear(e);
    }
    let statisticsFor = ()=>{
        switch(year){
            case "0":
                return "Статистика по Украине за период с 15 апреля 2020 по текущий момент"
            case "2020":
                return "Статистика по Украине за период с 15 апреля по 31 декабря 2020 года"
            case "2021":
                return "Статистика по Украине за период с 01 января 2021 года по текущий момент"
            case "2022":
                return "Статистика по Украине за период с 01 января 2022 года по текущий момент"
            case "2023":
                return "Статистика по Украине за период с 01 января 2023 года по текущий момент"
            case "2024":
                return "Статистика по Украине за период с 01 января 2024 года по текущий момент"
            case "2025":
                return "Статистика по Украине за период с 01 января 2025 года по текущий момент"
            default:
                return "Статистика по Украине за период с 15 апреля по текущий момент"
        }
    }
    //сплюсовываем все модели отрезая номенклатурные номера
    const getSumModel=()=>{
        let sumModel = [];
        let nameModel = [];
        props.moduleSalesStatistics.map((p, i)=>{
            //удаляем номенклатурный номер и последний дефис
            let nameModul = p.moduls_name.replace("-"+p.nomenclatures_id, '');
            //перебираем новый массив, если такое название есть, плюсуем количество, если нет, делаем новую запись
            // let res = nameModel.indexOf(nameModul);
            let res = nameModel.findIndex(item => item.name === nameModul);
            if(res === -1){
                nameModel.push({name:nameModul, coll: p.sum_coll})
            }else{
                nameModel[res].coll = Number(nameModel[res].coll) + Number(p.sum_coll)
            }
        })

        return nameModel.sort((a,b)=>Number(b.coll)-Number(a.coll))
    }
    return(
        <div>
            <MenuStatistics />
            <div>
                <h1>Выбрать год:</h1>
                <div className={s.mainYear}>
                    <div onClick={()=>handleClick("0")}>Все</div>
                    <div onClick={()=>handleClick("2020")}>2020</div>
                    <div onClick={()=>handleClick("2021")}>2021</div>
                    <div onClick={()=>handleClick("2022")}>2022</div>
                    <div onClick={()=>handleClick("2023")}>2023</div>
                    <div onClick={()=>handleClick("2024")}>2024</div>
                    <div onClick={()=>handleClick("2025")}>2025</div>
                </div>
            </div>
            <div>
            <h1>
                {
                    statisticsFor()
                }
                
            </h1>
            <div className={s.main+" "+s.header}>
                    <div>#</div>
                    <div>Название</div>
                    <div>Кол. шт.</div>
                </div>
            {
                getSumModel(props.moduleSalesStatistics).map((p, i)=>{
                    return(
                        <div className={s.main} key={i}>
                            <div>{i+1}</div>
                            <div>{p.name}</div>
                            <div>{p.coll}</div>
                        </div>
                    )
                })
                
            }
            
            </div>
        </div>
    )
}
export default StatisticsModelModules;