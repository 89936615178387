import React from 'react';
import s from './menu.module.css';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';


const Menu = (props) =>{
    let m = new Date()
    m = m.getMonth()+1
    let [state, setState] = useState(false)
    let year = new Date().getFullYear()
    return(
    <div className={s.mainDiv}>
        <div className={state ? s.show+" "+s.main : s.none}>
            <div className={s.close} onClick={()=>setState(!state)}>Х</div>
            <NavLink to={`/admin/ddc/${year}/`+m}><div onClick={()=>setState(!state)}>ДДС</div></NavLink>
            <NavLink to={`/admin/ddcNew/${year}`}><div onClick={()=>setState(!state)}>ДДС New</div></NavLink>
            <NavLink to={"/cash/"+m}><div onClick={()=>setState(!state)}>Касса</div></NavLink>
            {/* <NavLink to="/admin/moneydel"><div onClick={()=>setState(!state)}>Разносим НП</div></NavLink> */}
            <NavLink to="/admin/specification"><div onClick={()=>setState(!state)}>Себестоимость</div></NavLink>
            {/* <NavLink to={"/admin/shipment/"+m}><div onClick={()=>setState(!state)}>Отгрузки</div></NavLink> */}
            <NavLink to={"/admin/receivables"}><div onClick={()=>setState(!state)}>Дебиторка</div></NavLink>
            <NavLink to={"/admin/accountsPayable"}><div onClick={()=>setState(!state)}>Кредиторка</div></NavLink>
            {/* <NavLink to={"/admin/shipmentStatistics/0"}><div onClick={()=>setState(!state)}>Статистика отгрузок</div></NavLink> */}
            {/* <NavLink to={"/admin/plan2022"}><div onClick={()=>setState(!state)}>План 2022</div></NavLink> */}
            <NavLink to={"/admin/sales/44"}><div onClick={()=>setState(!state)}>Открепить</div></NavLink>
        </div>
        <div className={state ? s.none : s.show+" "+s.button} onClick={()=>setState(!state)}>
            Меню
        </div>
    </div>)
}
export default Menu;