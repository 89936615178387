import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    addNewMeeting,
    changeMeetingText,
    deleteMeeting,
    closeMeeting,
    changeDateReminderKp
} from '../../../redux/sales-reducer';
import { getAllAgents } from '../../../redux/company-reducer';
import { getCompanyList } from '../../../redux/other-reducer';
import Meeting from './Meeting';
import Cookies from 'js-cookie';
import instance from '../../../api/api'; // Імпорт instance для заміни функції

const MeetingContainer = (props) => {
    const { idManager } = useParams();
    const [currentPersonalId, setCurrentPersonalId] = useState(idManager);
    const [isFetched, setIsFetched] = useState(false); // Додаємо прапор

    const getAllMeetingWithManager = async (id) => {
        try {
            if (isFetched) return; // Запобігаємо повторним запитам
            const response = await instance.get(`getAllMeetingWithManager/${id}`);
            console.log(response.data);
            props.updateMeetings(response.data);
            setIsFetched(true); // Прапор, що дані завантажені
        } catch (error) {
            console.error('Error fetching meetings:', error);
        }
    };

    useEffect(() => {
        const isAdmin = !!Cookies.get('adminID');
        const personalId = isAdmin || Cookies.get('id') === currentPersonalId
            ? idManager
            : Cookies.get('id');

        if (currentPersonalId !== personalId) {
            setCurrentPersonalId(personalId);
            setIsFetched(false); // Скидаємо прапор при зміні ID
        }
    }, [idManager, currentPersonalId]);

    useEffect(() => {
        getAllMeetingWithManager(currentPersonalId); // Викликаємо тільки при зміні ID
        props.getAllAgents();
        props.getCompanyList();
    }, [currentPersonalId]);

    return (
        <div>
            <Meeting {...props} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    allMeetingWithManager: state.sales.allMeetingWithManager,
    allAgents: state.company.allAgents,
    companyList: state.other.companyList,
});

const mapDispatchToProps = (dispatch) => ({
    updateMeetings: (meetings) => dispatch({ type: 'UPDATE_MEETINGS', payload: meetings }),
    getAllAgents: () => dispatch(getAllAgents()), // Явно передаємо action creator
    getCompanyList: () => dispatch(getCompanyList()), // Явно передаємо action creator
});

export default compose(
    connect(mapStateToProps, {
        getAllAgents,
        getCompanyList,
        addNewMeeting,
        changeMeetingText,
        deleteMeeting,
        closeMeeting,
        changeDateReminderKp
    })
)(MeetingContainer);
