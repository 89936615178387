import React, { useEffect, useState } from 'react';
import instance from '../../../../api/api';
import AccessoriesList from './AccessoriesList';

const AccessoriesListContainer = () => {
    const [accessoriesList, setAccessoriesList] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchAccessoriesList = async () => {
        try {
            setLoading(true);
            await instance.get('getAccessoriesList').then(response => {
                setAccessoriesList(response.data);
            });
        } catch (error) {
            console.error('Error fetching accessories list:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAccessoriesList(); // Запит виконується один раз при завантаженні компонента
    }, []);

    return (
        <div>
            {loading ? (
                <p>Завантаження...</p>
            ) : (
                <AccessoriesList accessoriesList={accessoriesList} />
            )}
        </div>
    );
};

export default AccessoriesListContainer;
